export const environment =
{
  production: false,
  auth0: {
    domain: 'https://falx-de.us.auth0.com',
    clientId: 'sWgbVDhh8AZyJfZ6gQQWP3tlGF9U0Eig',
    authorizationParams: {
      audience: 'https://portal-pab-dev',
      redirect_uri: 'https://dyn-pb-ac.amlab7.com/callback'
    },
    errorPath: '/callback',
  },
  apiUrl: 'https://dyn-pb-ac.amlab7.com/api',
  version : 'V2.1.0'
};
